class GroupManager {
    static async getGroupTopic(group, defaultTopic = null) {
        if (defaultTopic === null) {
            defaultTopic = {
                "id": -1,
                "name": "None"
            };
        }
        return defaultTopic;
    }    
}