class GroupBrowser {
    constructor(container, options = {}) {
        this.container = $(container);

        this.groups = null;
        this.groupsById = {};

        this.opts = Object.assign({
            "sortBy": "alphabetically",
            "groupByTopics": true,
            "hideTopicsOnFilter": true,
            "excludeTopicsOnFilter": true,
            
            "allowedGroups": true,
            "allowedDistricts": true,
            "allowedTargetGroups": true,
            "allowedActivities": true,
            "allowedTopics": true,
            
            "allowFilterLocations": true,
            "allowFilterActivities": true,
            "allowFilterTargetGroups": true,

            "showTargetGroups": true,
            "showLocations": true,
            "locationForceDistrict": true,
            "locationIncludeDistrict": true,
            "showActivities": true
        }, options);

        this.filter = {
            "districts": false,
            "buildings": false,
            "targetGroups": false,
            "activites": false,
            "search": false
        };

        if (this.container.length === 0) {
            return;
        }

        console.log(this);
        
        this.init();
    }

    async init() {
        this.container.html(`
            <div class="group-browser-interface">

            </div>
            <div class="group-browser-groups row g-2">
                <div class="col s12" style="text-align: center;">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Wird geladen...</span>
                    </div>
                </div>
            </div>
        `);
        
        let groups = await this.getGroups();
        let groupView = this.container.find(".group-browser-groups");
        groupView.html("");
        let groupByTopics = this.opts.groupByTopics && !this.anyFilterActive();
        let topics = [];
        for (let group of groups) {
            if (groupByTopics) {                
                if (!topics.includes(group.topic.id)) {
                    groupView.append(`
                        <h3 class="col-sm-12" data-position="-1" data-topic-position="${group.topic.position}">${group.topic.name}</h3>
                    `);
                    topics.push(group.topic.id);
                }
            }
            groupView.append(await this.getGroupView(group));
        }

        // Filter
        let groupInterface = $(`<div class="group-browser-filters"></div>`);
        this.container.find(".group-browser-interface").append(groupInterface);
        let groupFilterSync = $(`<div class="group-browser-filter-sync"></div>`);
        this.container.find(".group-browser-interface").append(groupFilterSync);
        
        groupInterface.html();
        if (this.opts.allowFilterLocations) {
            let locationFilterDiv = $(`<div class="group-browser-filter-locations"></div>`);
            groupInterface.append(locationFilterDiv);
            new CategorySelector(locationFilterDiv, {
                title: "Orte",
                icon: "fa-solid fa-location-dot",
                colorSelection: "semanticRoot",
                semantic: "locations/districts",
                syncWith: groupFilterSync,
                onSelectionChange: this.filterLocations.bind(this)
            });
        }
        
        if (this.opts.allowFilterTargetGroups) {
            let targetGroupsFilterDiv = $(`<div class="group-browser-filter-targetgroups"></div>`);
            groupInterface.append(targetGroupsFilterDiv);
            new CategorySelector(targetGroupsFilterDiv, {
                title: "Zielgruppen",
                icon: "fa-solid fa-people-group",
                colorSelection: "hierarchy",
                semantic: "targetgroups",
                syncWith: groupFilterSync,
                onSelectionChange: this.filterTargetGroups.bind(this)
            });
        }
        
        if (this.opts.allowFilterActivities) {
            let activitiesFilterDiv = $(`<div class="group-browser-filter-activities"></div>`);
            groupInterface.append(activitiesFilterDiv);
            new CategorySelector(activitiesFilterDiv, {
                title: "Aktivitäten",
                icon: "fa-solid fa-tag",
                colorSelection: "semanticParent",
                semantic: "activities",
                syncWith: groupFilterSync,
                onSelectionChange: this.filterActivities.bind(this)
            });
        }

        this.updateFilter();
    }
    
    filterLocations(locations) {
        this.filter.districts = locations;
        this.updateFilter();
    }
    
    filterTargetGroups(targetGroups) {
        this.filter.targetGroups = targetGroups;
        this.updateFilter();
    }

    filterActivities(activities) {
        this.filter.activites = activities;
        this.updateFilter();
    }

    updateFilter() {
        if (this.anyFilterActive() && this.opts.hideTopicsOnFilter) {
            this.container.find("h3").hide();
        } else {
            this.container.find("h3").show();
        }

        let that = this;
        this.container.find(".group-browser-group").each(function() {
            let groupId = $(this).data("group")
            let group = that.groupsById[groupId];
            if (that.matchesFilter(group)) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
        this.sortGroups();
    }

    matchesFilter(group) {
        let matchesDistricts = true;
        let matchesActivities = true;
        let matchesTargetGroups = true;

        // Check districts (any) with hierarchy
        if (this.filter.districts.length > 0) {
            matchesDistricts = Categories.verifyFilter(this.filter.districts, group.locations, true, false, true);
        }

        // Check activities (any), no hierarchy
        if (this.filter.activites.length > 0) {
            matchesActivities = Categories.verifyFilter(this.filter.activites, group.activities, true, false, false);
        }

        // Check target groups (all), with hierarchy
        if (this.filter.targetGroups.length > 0) {
            matchesTargetGroups = Categories.verifyFilter(this.filter.targetGroups, group.targetgroups, false, true, false);
        }

        return matchesDistricts && matchesActivities && matchesTargetGroups;
    }

    async getGroupView(group) {
        let pageLink = "";
        if (group.pageId !== "-1") {
            pageLink = `
                <div class="group-more-link">
                    <a href="${group.page.properties.path}">Mehr Informationen</a>
                </div>`;
        }
        let description = group.description;
        let image = "";
        if (group.pictureId !== "-1") {
            let imageClass = "group-image";
            if (group.picture_as_logo) {
                imageClass += " group-logo";
            }
            image = `
                <div class="group-image-wrapper">
                    <img src="${group.picture.medium}" class="${imageClass}">
                </div>
            `;
        }
        let badgeContainerBelow = "";
        let badgeContainerAbove = "";

        let badges = [];
        if (this.opts.showLocations) {
            for (let location of group.locations) {
                badges.push(await Categories.getBadge(location, {"type": "location"}));
            }
        }
        if (this.opts.showTargetGroups) {
            for (let targetgroup of group.targetgroups) {
                badges.push(await Categories.getBadge(targetgroup, {"type": "targetgroup"}));
            }
        }
        if (this.opts.showActivities) {
            for (let activity of group.activities) {
                badges.push(await Categories.getBadge(activity, {"type": "activity"}));
            }
        }
        if (badges.length > 0) {
            if (this.opts.badgePosition === "below") {
                badgeContainerBelow = `
                    <div class="group-badges below">
                        ${badges.join("")}
                    </div>`;
            } else {
                badgeContainerAbove = `
                    <div class="group-badges above">
                        ${badges.join("")}
                    </div>`;
            }
        }
        return `
            <div class="group-browser-group col-sm-12 col-md-6" data-group="${group.id}" data-name="${group.name}" data-topic-position="${group.topic.position}">
                <div class="card">
                    <div class="card-content">
                        <div class="group-name">${group.name}</div>
                        ${badgeContainerAbove}
                        <div class="group-presentation">
                            ${image}
                            <div class="group-info">
                                <div class="group-description">
                                    ${description}
                                </div>
                            </div>
                        </div>
                        ${badgeContainerBelow}
                        ${pageLink}
                    </div>
                </div>
            </div>
        `;
    }

    sortGroups() {
        let includeTopic = this.opts.groupByTopics;
        if (this.anyFilterActive() && this.opts.excludeTopicsOnFilter) {
            includeTopic = false;
        }

        let that = this;
        let compareLocally = function(a, b) {
            if ($(a).is("h3")) {
                if ($(b).is("h3")) {
                    return parseInt($(a).data("position")) - parseInt($(b).data("position"));
                } else {
                    return -1;
                }
            } else if ($(b).is("h3")) {
                return 1;
            }

            switch (that.opts.sortBy) {
                default:
                case "id":
                    return parseInt($(a).data("group")) - parseInt($(b).data("group"));
                case "alphabetically":
                    return $(a).data("name").localeCompare($(b).data("name"));
            }
        }

        let groupContainer = this.container.find(".group-browser-groups");

        let elements = groupContainer.children().sort(function(a, b) {
            if (includeTopic) {
                let topicA = $(a).data("topic-position");
                let topicB = $(b).data("topic-position");
                if (topicA !== topicB) {
                    return parseInt(topicA) - parseInt(topicB);
                }
            }
            return compareLocally(a, b);
        });
        groupContainer.append(elements);
    }

    intersection(setA, setB) {
        let arrayA = Array.from(setA.values());
        let arrayB = Array.from(setB.values());
        let intersection = arrayA.filter(el => arrayB.indexOf(el) >= 0);
        return new Set(intersection);
    }

    isGroupAllowed(group) {
        if (this.opts.allowedGroups !== true && !this.opts.allowedGroups.includes(group.id)) {
            return false;
        }
        
        if (this.opts.allowedTopics !== true && !this.opts.allowedTopics.includes(group.topicId)) {
            return false;
        }

        let pairs = {
            "allowedTargetGroups": "targetgroupsIds",
            "allowedActivities": "activitiesIds",
            // "allowedDistricts": "locationsIds",
        };
        for (let key in pairs) {
            if (this.opts[key] === true) {
                continue;
            }
            let groupKey = pairs[key];
            let allowedSet = new Set(this.opts[key]);
            let groupSet = new Set(group[groupKey]);
            if (this.intersection(allowedSet, groupSet).size === 0) {
                return false;
            }
        }

        return true;
    }

    async getGroups() {
        if (this.groups !== null) {
            return this.groups;
        }
        try {
            this.groupsById = {};
            let result = await $.ajax({
                "url": "api/groups/get_groups"
            });
            this.groups = [];
            for (let group of result.groups) {
                if (this.isGroupAllowed(group)) {                
                    this.groups.push(group);
                }
                this.groupsById[group.id] = group;
            }
            return this.groups;
        } catch (e) {
            this.groups = [];
            console.log(e);
            this.container.html("<i>Gruppen konnten nicht abgerufen werden</i>");
            return [];
        }
    }

    anyFilterActive() {
        for (let key in this.filter) {
            if (this.filter[key] !== false) {
                if (Array.isArray(this.filter[key])) {
                    if (this.filter[key].length > 0) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }
};

$(function() {
    $(".group-browser").each(function() {
        let options = {};
        options.sortBy = $(this).data("sort") || "alphabetically";

        options.groupByTopics = $(this).data("groupByTopic") ?? true;
        options.allowFilterTargetGroups = $(this).data("allowFilterTargetGroups") ?? true;
        options.allowFilterActivities = $(this).data("allowFilterActivities") ?? true;
        options.allowFilterLocations = $(this).data("allowFilterLocations") ?? true;
        
        options.hideTopicsOnFilter = $(this).data("hide-topics-on-filter") || true;
        
        options.allowedGroups = $(this).data("groups") || true;
        options.allowedTopics = $(this).data("allowedTopics") || true;
        options.allowedTargetGroups = $(this).data("allowedTargetGroups") || true;
        options.allowedActivities = $(this).data("allowedActivities") || true;

        options.badgePosition = $(this).data("badgePosition") || "above";
        let badgeFilter = $(this).data("badges") || "all";
        if (badgeFilter === "all") {
            badgeFilter = "locations,targetgroups,activities";
        }
        let badgeTypes = badgeFilter.split(",");
        options.showLocations = badgeTypes.includes("locations");
        options.showTargetGroups = badgeTypes.includes("targetgroups");
        options.showActivites = badgeTypes.includes("activities");

        let arrays = ["allowedGroups", "allowedTopics", "allowedTargetGroups", "allowedActivities"];
        for (let arrayName of arrays) {
            if (!Array.isArray(options[arrayName]) || options[arrayName].length === 0) {
                options[arrayName] = true;
            }
        }

        let bools = ["groupByTopics", "allowFilterTargetGroups", "allowFilterActivities", "allowFilterLocations"];
        for (let bool of bools) {
            if (options[bool] === "true") {
                options[bool] = true;
            } else if (options[bool] === "false") {
                options[bool] = false;
            }
        }

        new GroupBrowser($(this), options);
    });
});